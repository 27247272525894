/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core';

let theme = createTheme({
  palette: {
    background: {
      default: "#fff"
    },
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Urbanist", "sans-serif"].join(","),
    h3: {
      fontFamily: ["Urbanist", "Montserrat", "sans-serif"].join(","),
    },
    h4: {
      fontFamily: ["Urbanist", "Montserrat", "sans-serif"].join(","),
    },
    h5: {
      fontFamily: ["Urbanist", "Montserrat", "sans-serif"].join(","),
    },
  },
})
theme = responsiveFontSizes(theme)

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header></Header>
      <main>{children}</main>
      <Footer></Footer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
