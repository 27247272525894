import React from "react"
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import { Link as RouterLink } from "gatsby"
import { ContactInfo } from "./contactModal"

const useStyles = makeStyles(theme => ({
  footer: {
    textAlign: "center",
    padding: "1rem 1rem 0",
    background: "#ddd"
  },
  imageContainer:{
    justifyContent: "center",
    alignItems: "center",
  },
  paragraph: {
    margin: "1rem auto",
    maxWidth: "70vw",
  },
  links: {
    textDecoration: "none",
    color: "inherit",
    ":hover": {
      textDecoration: "underline",
    },
  },
  text: {
    fontSize: "0.8rem",
  },
  ownership: {
    fontSize: "0.8rem",
    margin: "1rem auto 0",
  },
  logo: {
    maxWidth: "70%",
    width: "200px",
  },
  link: {
    display: "block",
    margin: "1rem",
    fontSize: "1.2rem",
    color: "#fff",
  },
  icon: {
    verticalAlign: "middle",
  },
  separator: {
    width: "75vw",
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <Grid container className={classes.footer}>
      <Grid item className={classes.paragraph}>
        <Typography style={{ fontSize: "1.1rem" }}>
          Brampton &#8226; Mississauga &#8226; Toronto &#8226; York &#8226;
          Oakville &#8226; Burlington &#8226; Milton &#8226; Etobicoke
        </Typography>
        <ContactInfo
          linkStyle={{
            margin: "1rem",
            fontSize: "1.2rem",
          }}
          iconStyle={{ verticalAlign: "middle" }}
        />
      </Grid>
      <hr className={classes.separator} />
      {/* <Grid item sm={12} className={classes.paragraph}>
        <StaticImage
          src="../images/akal-logo-light.png"
          formats={["auto", "webp", "avif"]}
          alt="Akal Insurance"
          className={classes.imageContainer}
        />
      </Grid> */}
      <Grid item sm={12} className={classes.paragraph}>
        <Typography className={classes.text}>
          &copy; 2021 All rights reserved.
        </Typography>
      </Grid>
      <Grid item sm={12} className={classes.paragraph}>
        <Typography className={classes.text}>
          Information on this web site may contain inaccuracies or typographical
          errors. Information may be changed or updated without notice. This web
          site does not constitute an offer or contract. In no event will this
          website be liable to any party for any direct, indirect, special or
          other consequential damages for any use of this web site, or any other
          hyperlinked web site, including, without limitation, any lost profits,
          business interruption, loss of programs or other data or otherwise,
          even if we are expressly advised of the possibility of such damages.
        </Typography>
      </Grid>
      <Grid item sm={12} className={classes.paragraph}>
        <Typography className={classes.text}>
          Amrish Prashar | Akal Insurance
        </Typography>
        <Typography className={classes.text}>
          1200 Derry Rd E #10, Mississauga, ON L5T 0B3
        </Typography>
        <Typography className={classes.text}>
          <RouterLink className={classes.links} to="/terms">
            TERMS OF USE
          </RouterLink>
          {"  |  "}
          <RouterLink className={classes.links} to="/policy">
            PRIVACY POLICY
          </RouterLink>
        </Typography>
        <Typography className={classes.ownership}>
          Website design and implementation by
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="mailto:kashugoyal@gmail.com"
          >
            &nbsp; Kashish Goyal
          </a>
        </Typography>
      </Grid>
    </Grid>
  )
}
